import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ConfigurationIndex, ConfigurationSave, ConfigurationDelete, ConfigurationRead } from '@/api/updateUserInfo/users';
import Head from '@/components/head/index';
import { getAPCStructureList } from '@/api/PublicAPI';
import mailListApp from '@/components/StaffSelect/mailListApp';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
export default {
  name: 'outbound',
  data: function data() {
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '审批流程配置',
      loading: true,
      ClassSearch: '',
      name: '',
      userIde: '',
      tableData: [],
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      ShowType: true,
      text: [],
      radioEs: false,
      condition: [],
      staffResult: [],
      staffDisableArr: [],
      mailListVisibleEs: false,
      radio: false,
      staffSelectVisible: false,
      search_title: '',
      textMap: {
        update: '编辑审批流程',
        create: '新增审批流程',
        details: '审批流程详情'
      },
      staffResultEs: [],
      dialogFormVisible: false,
      bumenoptions: [],
      structure_idEs: '',
      executiveArr: [],
      financeArr: [],
      FormJson: {
        id: '',
        name: '',
        type: '',
        structure_id: '',
        structure_name: [],
        executive: '',
        finance: '',
        executiveArray: [],
        financeArray: []
      },
      FormJsons: {
        id: '',
        name: '',
        type: '',
        structure_id: '',
        structure_name: [],
        executive: '',
        finance: '',
        executiveArray: [],
        financeArray: []
      },
      executiveShow: true,
      typeName: '',
      executivekey: '',
      finance: '',
      rules: {
        name: [{
          required: true,
          message: '请输入审批名称',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择审批类型',
          trigger: 'blur'
        }],
        structure_id: [{
          required: true,
          message: '请选择提交人员所属部门',
          trigger: 'blur'
        }]
      },
      chargeList: [{
        id: 1,
        val: '支付审批流'
      }],
      dialogStatus: '',
      mergeId: []
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {
      this.getList(this.query.page, this.query.pagesize);
    } else {}
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs,
    mailListApp: mailListApp
  },
  methods: _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
    QueryClick: function QueryClick() {
      this.getList(this.query.page, this.query.pagesize);
    },
    MergeFocus: function MergeFocus() {
      var _this2 = this;
      this.mergeId = [];
      this.FormJson.executiveArray.forEach(function (item) {
        _this2.mergeId.push(item.id);
      });
      this.FormJson.financeArray.forEach(function (item) {
        _this2.mergeId.push(item.id);
      });
      // console.log(this.mergeId);
    },
    getStructure: function getStructure(id) {
      var _this3 = this;
      var _thiss = this;
      var _this = this.FormJson;
      _this.id = id;
      if (this.FormJson.id == '' || this.FormJson.id == undefined) {
        getAPCStructureList().then(function (respomse) {
          _thiss.bumenoptions = respomse.data;
        });
      } else {
        getAPCStructureList({
          id: this.FormJson.id,
          type: '1'
        }).then(function (respomse) {
          _thiss.bumenoptions = respomse.data;
          _this3.infodata(id);
        });
      }
    },
    getStructures: function getStructures() {
      this.ShowType = false;
    },
    NewApprover: function NewApprover(type) {
      var data = {
        'id': '',
        'name': ""
      };
      if (type == 'executive') {
        this.FormJson.executiveArray.push(data);
      } else {
        this.FormJson.financeArray.push(data);
        this.executiveShow = false;
      }
    },
    DeleteCondition: function DeleteCondition(type, index) {
      if (type == 'executive') {
        this.FormJson.executiveArray.splice(index, 1);
      } else {
        this.FormJson.financeArray.splice(index, 1);
        if (this.FormJson.financeArray.length < 1) {
          this.executiveShow = true;
        } else {
          this.executiveShow = false;
        }
      }
    },
    ExecutiveFocus: function ExecutiveFocus(type, index, id) {
      this.text = [];
      this.typeName = type;
      this.radioEs = true;
      this.userIde = String(id);
      this.mailListVisibleEs = true;
      if (type == 'executive') {
        this.executivekey = index;
      } else {
        this.financekey = index;
      }
      this.text.push(Number(id));
      this.MergeFocus();
    },
    inputChange: function inputChange(type, index) {
      if (type == 'executive') {
        this.executivekey = index;
        this.FormJson.executiveArray[index].name = this.FormJson.executiveArray[index].name.replace(/([\u4e00-\u9fa5])|(\s)|[^\d\w]/g, '');
      } else {
        this.financekey = index;
        this.FormJson.financeArray[index].name = this.FormJson.financeArray[index].name.replace(/([\u4e00-\u9fa5])|(\s)|[^\d\w]/g, '');
      }
    },
    SharedSustomersSor: function SharedSustomersSor(resultNameArr, result) {
      if (this.typeName == 'executive') {
        this.FormJson.executiveArray[this.executivekey].id = result.toString();
        this.FormJson.executiveArray[this.executivekey].name = resultNameArr.toString();
      } else {
        this.FormJson.financeArray[this.financekey].id = result.toString();
        this.FormJson.financeArray[this.financekey].name = resultNameArr.toString();
      }
    },
    getList: function getList(page, pagesize) {
      var _this4 = this;
      this.loading = true;
      ConfigurationIndex({
        search_title: this.name,
        pagesize: String(pagesize),
        page: String(page)
      }).then(function (respomse) {
        _this4.query.total = respomse.data.total;
        _this4.tableData = respomse.data.data;
        _this4.loading = false;
      });
    },
    selectChanged: function selectChanged(value) {
      //状态
      this.value = value;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    handleSizeChange: function handleSizeChange(val) {
      this.query.pagesize = val;
      this.getList(this.query.page, val);
    },
    handleCurrentChange: function handleCurrentChange(val) {
      this.query.page = val;
      this.getList(val, this.query.pagesize);
    },
    //组织部门
    structureclick: function structureclick(val) {
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      var adad = share == ',' ? '' : share;
      if (this.course != 1) {
        this.structure_idEs = adad;
      }
    },
    resetTempss: function resetTempss() {
      this.ShowType = true;
      this.executiveShow = true;
      this.dialogFormVisible = true;
      this.resetTempR();
    },
    edit: function edit() {
      this.ShowType = true;
    },
    resetTempR: function resetTempR() {
      // this.resetForm('FormJson')

      this.FormJson = {
        name: '',
        type: '',
        structure_id: '',
        executive: '',
        finance: '',
        executiveArray: [],
        financeArray: []
      };
      this.structure_idEs = '';
    },
    resetTemps: function resetTemps() {
      this.FormJson = {
        name: '',
        type: '',
        structure_id: '',
        executive: '',
        finance: '',
        executiveArray: [],
        financeArray: []
      };
      this.structure_idEs = '';
      this.dialogFormVisible = false;
    },
    infodata: function infodata(id) {
      var _this5 = this;
      var _this = this.FormJson;
      var that = this;
      ConfigurationRead({
        id: id
      }).then(function (res) {
        _this.name = res.data.title;
        _this.type = res.data.type;
        _this5.structure_idEs = res.data.structure_ids;
        _this.structure_id = res.data.structure_ids.split(',').map(function (e) {
          var _this5$getDepartmentN = _this5.getDepartmentNameById(e, that.bumenoptions),
            id = _this5$getDepartmentN.id;
          return id;
        });
        _this5.FormJson.structure_name = res.data.structure_names;
        _this.executiveArray = res.data.leader_usernames;
        _this.financeArray = res.data.finance_usernames;
        if (_this.financeArray.length < 1) {
          _this5.executiveShow = true;
        } else {
          _this5.executiveShow = false;
        }
      });
    },
    DeleteClick: function DeleteClick(id) {
      var _this6 = this;
      this.$confirm('删除后数据不可恢复，确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        ConfigurationDelete({
          id: id
        }).then(function (res) {
          _this6.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success',
            duration: 2000
          });
          _this6.getList(_this6.query.page, _this6.query.pagesize);
        });
      }).catch(function () {
        _this6.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }, "SharedSustomersSor", function SharedSustomersSor(resultNameArr, result) {
    console.log(resultNameArr, result);
    if (this.typeName == 'executive') {
      this.FormJson.executiveArray[this.executivekey].id = Number(result);
      this.FormJson.executiveArray[this.executivekey].name = resultNameArr.toString();
    } else {
      this.FormJson.financeArray[this.financekey].id = Number(result);
      this.FormJson.financeArray[this.financekey].name = resultNameArr.toString();
    }
  }), "staffSelectConfirm", function staffSelectConfirm(resultNameArr, result) {
    var _this7 = this;
    //绑定账号
    CallPhoneBinding({
      id: String(this.accountNumberId),
      user_id: String(result),
      user_name: String(resultNameArr)
    }).then(function (res) {
      _this7.$notify({
        title: '成功',
        message: '绑定成功',
        type: 'success'
      });
      // this.query.page = 1;
      _this7.currentPage4 = 1;
      _this7.getList(_this7.query.page, _this7.query.pagesize);
    });
  }), "Announcement", function Announcement(FormJson) {
    var _this8 = this;
    //新增审批流校验form表单
    this.$refs[FormJson].validate(function (valid) {
      if (valid) {
        _this8.Submit();
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }), "Qvund", function Qvund(FormJson) {
    // this.FormJson = this.FormJsons
    this.resetForm('FormJson');
    this.resetTemps();
  }), "Submit", function Submit() {
    // 新增审批流
    var arr = [];
    var arr2 = [];
    var _this = this.FormJson;
    if (_this.executiveArray.length > 0 || _this.financeArray.length > 0) {
      _this.executiveArray.forEach(function (item, index) {
        if (item.name == '') {
          arr.push(index);
        }
      });
      _this.financeArray.forEach(function (item, index) {
        if (item.name == '') {
          arr2.push(index);
        }
      });
    }
    if (arr.length > 0 || arr2.length > 0) {
      this.$message({
        message: '请完善信息~',
        type: 'warning'
      });
    } else {
      this.Submit1(_this.financeArray);
    }
  }), "EditorialAnnouncement", function EditorialAnnouncement(FormJson) {
    var _this9 = this;
    //编辑班级
    this.$refs[FormJson].validate(function (valid) {
      if (valid) {
        _this9.Submit();
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }), "Submit1", function Submit1(Arr) {
    var _this10 = this;
    var _this = this.FormJson;
    var data = {
      title: _this.name,
      type: _this.type,
      structure_ids: this.structure_idEs,
      leader_uids: JSON.stringify(_this.executiveArray),
      finance_uids: JSON.stringify(_this.financeArray)
    };
    if (_this.id != '') {
      if (Arr.length > 0) {
        data.id = _this.id;
        ConfigurationSave(data).then(function (res) {
          _this10.query.page = 1;
          _this10.dialogFormVisible = false;
          _this10.FormJson = _this10.FormJsons;
          _this10.resetForm('FormJson');
          _this10.getList(_this10.query.page, _this10.query.pagesize);
        });
      } else {
        this.$message({
          message: '请添加财务审批人~',
          type: 'warning'
        });
      }
    } else {
      if (Arr.length > 0) {
        ConfigurationSave(data).then(function (res) {
          _this10.query.page = 1;
          _this10.dialogFormVisible = false;
          _this10.FormJson = _this10.FormJsons;
          _this10.resetForm('FormJson');
          _this10.getList(_this10.query.page, _this10.query.pagesize);
        });
      } else {
        this.$message({
          message: '请添加财务审批人~',
          type: 'warning'
        });
      }
    }
  }), "resetForm", function resetForm(formName) {
    this.$refs[formName].resetFields();
  })
};