var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container ApprovalProcess" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "105px" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 8, sm: 8, md: 7, lg: 6, xl: 5 } },
                      [
                        _c("el-input", {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "审批流名称搜索",
                            size: "small",
                            clearable: "",
                          },
                          model: {
                            value: _vm.name,
                            callback: function ($$v) {
                              _vm.name = $$v
                            },
                            expression: "name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.QueryClick },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 4, sm: 4, md: 3, lg: 3, xl: 3 } },
                      [
                        _c(
                          "el-button",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: ["新增"],
                                expression: "['新增']",
                              },
                            ],
                            attrs: { type: "success", size: "small" },
                            on: {
                              click: function ($event) {
                                ;(_vm.dialogStatus = "create"),
                                  (_vm.dialogFormVisible = true),
                                  _vm.resetTempss(),
                                  _vm.getStructure("")
                              },
                            },
                          },
                          [_vm._v("新建")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              { staticStyle: { "padding-top": "0" } },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      key: 1,
                      attrs: {
                        label: "审批流名称",
                        prop: "title",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      key: 2,
                      attrs: {
                        label: "审批类型",
                        prop: "imei",
                        align: "center",
                        width: "270",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.type == 1
                                  ? _c("span", [_vm._v("支付审批流")])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        4058932659
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      key: 3,
                      attrs: {
                        label: "提交人所属部门",
                        prop: "name",
                        align: "center",
                        width: "270",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return _vm._l(
                                scope.row.structure_names,
                                function (item, index) {
                                  return _c(
                                    "p",
                                    {
                                      key: index,
                                      staticStyle: {
                                        "margin-top": "0 !important",
                                        "margin-bottom": "0 !important",
                                      },
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                }
                              )
                            },
                          },
                        ],
                        null,
                        false,
                        3885077038
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      key: 4,
                      attrs: { label: "审批流程", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["读取详情"],
                                        expression: "['读取详情']",
                                      },
                                    ],
                                    staticStyle: { color: "#1890ff" },
                                    on: {
                                      click: function ($event) {
                                        ;(_vm.dialogStatus = "details"),
                                          (_vm.dialogFormVisible = true),
                                          _vm.infodata(scope.row.id),
                                          _vm.getStructures()
                                      },
                                    },
                                  },
                                  [_vm._v("审批流详情")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1779843207
                      ),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      key: 5,
                      attrs: {
                        label: "创建人",
                        prop: "create_username",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      key: 6,
                      attrs: {
                        label: "创建时间",
                        prop: "create_time",
                        align: "center",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "操作", align: "center" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["编辑"],
                                        expression: "['编辑']",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        ;(_vm.dialogStatus = "update"),
                                          (_vm.dialogFormVisible = true),
                                          _vm.getStructure(scope.row.id),
                                          _vm.edit()
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value: ["删除"],
                                        expression: "['删除']",
                                      },
                                    ],
                                    staticStyle: { color: "red" },
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.DeleteClick(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2625184764
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
            _vm._v(" "),
            _c(
              "el-dialog",
              {
                attrs: {
                  top: "5vh",
                  title: _vm.textMap[_vm.dialogStatus],
                  visible: _vm.dialogFormVisible,
                },
                on: {
                  "update:visible": function ($event) {
                    _vm.dialogFormVisible = $event
                  },
                  close: _vm.resetTemps,
                },
              },
              [
                _c(
                  "el-form",
                  {
                    ref: "FormJson",
                    staticStyle: { width: "430px" },
                    attrs: {
                      model: _vm.FormJson,
                      rules: _vm.rules,
                      "label-position": "right",
                      "label-width": "150px",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "审批流名称", prop: "name" } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "small",
                            disabled: !_vm.ShowType,
                            placeholder: "请输入审批流名称",
                          },
                          model: {
                            value: _vm.FormJson.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormJson, "name", $$v)
                            },
                            expression: "FormJson.name",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "审批类型", prop: "type" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "small",
                              disabled: !_vm.ShowType,
                              placeholder: "请选择审批类型",
                            },
                            model: {
                              value: _vm.FormJson.type,
                              callback: function ($$v) {
                                _vm.$set(_vm.FormJson, "type", $$v)
                              },
                              expression: "FormJson.type",
                            },
                          },
                          _vm._l(_vm.chargeList, function (item, index) {
                            return _c("el-option", {
                              key: index,
                              attrs: { label: item.val, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "提交人员所属部门",
                          prop: "structure_id",
                        },
                      },
                      [
                        _vm.ShowType
                          ? _c("el-cascader", {
                              attrs: {
                                placeholder: "请选择部门",
                                options: _vm.bumenoptions,
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                                size: "small",
                                clearable: "",
                              },
                              on: { change: _vm.structureclick },
                              model: {
                                value: _vm.FormJson.structure_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.FormJson, "structure_id", $$v)
                                },
                                expression: "FormJson.structure_id",
                              },
                            })
                          : _c(
                              "div",
                              {
                                staticStyle: {
                                  padding: "6px",
                                  "padding-top": "3px",
                                  border: "1px solid #e4e7ed",
                                  "margin-top": "-5px",
                                },
                              },
                              _vm._l(
                                _vm.FormJson.structure_name,
                                function (item, index) {
                                  return _c(
                                    "p",
                                    {
                                      key: index,
                                      staticStyle: { "line-height": "18px" },
                                    },
                                    [_vm._v(" " + _vm._s(item))]
                                  )
                                }
                              ),
                              0
                            ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.ShowType
                      ? _c(
                          "div",
                          [
                            _vm._l(
                              _vm.FormJson.executiveArray,
                              function (item, index) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: index,
                                    attrs: { label: "下一主管审批人" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        size: "small",
                                        placeholder: "请选择主管审批人",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.ExecutiveFocus(
                                            "executive",
                                            index,
                                            item.id
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.inputChange(
                                            "executive",
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: item.name,
                                        callback: function ($$v) {
                                          _vm.$set(item, "name", $$v)
                                        },
                                        expression: "item.name",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-button", {
                                      staticStyle: {
                                        "margin-left": "5%",
                                        padding: "6px",
                                      },
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-close",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DeleteCondition(
                                            "executive",
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _vm._l(
                              _vm.FormJson.financeArray,
                              function (items, index) {
                                return _c(
                                  "el-form-item",
                                  {
                                    key: "info2-" + index,
                                    attrs: { label: "下一财务审批人" },
                                  },
                                  [
                                    _c("el-input", {
                                      staticStyle: { width: "80%" },
                                      attrs: {
                                        size: "small",
                                        placeholder: "请选择财务审批人",
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.ExecutiveFocus(
                                            "finance",
                                            index,
                                            items.id
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.inputChange(
                                            "finance",
                                            index
                                          )
                                        },
                                      },
                                      model: {
                                        value: items.name,
                                        callback: function ($$v) {
                                          _vm.$set(items, "name", $$v)
                                        },
                                        expression: "items.name",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-button", {
                                      staticStyle: {
                                        "margin-left": "5%",
                                        padding: "6px",
                                      },
                                      attrs: {
                                        type: "danger",
                                        size: "small",
                                        icon: "el-icon-close",
                                        circle: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.DeleteCondition(
                                            "finance",
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-left": "40%",
                                  width: "100%",
                                },
                              },
                              [
                                _vm.executiveShow
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "small",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.NewApprover("executive")
                                          },
                                        },
                                      },
                                      [_vm._v("新增主管审批人")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "warning", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.NewApprover("finance")
                                      },
                                    },
                                  },
                                  [_vm._v("新增财务审批人")]
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "20%",
                              "margin-top": "8%",
                            },
                          },
                          [
                            _c("h4", [_vm._v("审批人员")]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "-12%",
                                  "margin-left": "19%",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.FormJson.executiveArray,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: index,
                                        staticStyle: {
                                          width: "90px",
                                          height: "117px",
                                          "margin-top": "-10px",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "names" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", {
                                          staticClass: "normal_top_arrow",
                                        }),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.FormJson.financeArray,
                                  function (item, index) {
                                    return _c(
                                      "div",
                                      {
                                        key: "info2-" + index,
                                        staticStyle: {
                                          width: "90px",
                                          height: "117px",
                                          "margin-top": "-10px",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "names" }, [
                                          _vm._v(_vm._s(item.name)),
                                        ]),
                                        _vm._v(" "),
                                        index + 1 !==
                                        _vm.FormJson.financeArray.length
                                          ? _c("div", {
                                              staticClass: "normal_top_arrow",
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            ;(_vm.dialogFormVisible = false),
                              _vm.Qvund("FormJson")
                          },
                        },
                      },
                      [_vm._v("取消")]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      [
                        _vm.ShowType
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogStatus === "create"
                                      ? _vm.Announcement("FormJson")
                                      : _vm.EditorialAnnouncement("FormJson")
                                  },
                                },
                              },
                              [_vm._v("确认")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("mailListApp", {
              ref: "staffSelect",
              attrs: {
                item: _vm.userIde,
                radio: _vm.radioEs,
                text: _vm.text,
                mergeId: _vm.mergeId,
                condition: _vm.condition,
                visible: _vm.mailListVisibleEs,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.mailListVisibleEs = $event
                },
                SharedSustomersSor: _vm.SharedSustomersSor,
              },
              model: {
                value: _vm.staffResultEs,
                callback: function ($$v) {
                  _vm.staffResultEs = $$v
                },
                expression: "staffResultEs",
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }